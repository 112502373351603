import { Expose } from "class-transformer";
import { Entity } from "../entity";
import CourseLecture from "./courseLecture";
import CourseText from "./courseText";

export enum CourseType {
  STANDARD = "STANDARD",
  PMCC = "PMCC",
  EXAM = "EXAM",
  NPMCC = "NPMCC",
}

export enum CourseCaseType {
  NO = "NO",
  CASES_5 = "CASES_5",
  CASES_10 = "CASES_10",
  CASES_5_NO_REQ = "CASES_5_NO_REQ",
}

export default class CourseDetail extends Entity {
  @Expose() type!: CourseType;

  @Expose() is_voucher = false;
  @Expose() is_recommended = true;

  @Expose() max_participants!: number;
  @Expose() tuition_units!: number;

  @Expose() apprenticeship_category!: string;

  @Expose() course_text: CourseText | null = new CourseText();
  @Expose() lecture: CourseLecture | null = new CourseLecture();

  @Expose() course_cases!: string;
  @Expose() course_cases_booking_confirmation_text!: string;
  @Expose() course_cases_completed = 0;

  @Expose() zip_course_cases_link!: string;
}
